<template>
  <div class="diagnose-table-container">
    <div class="control">
      <div class="control_left">
        <a-popover
          trigger="click"
          overlayClassName="media_popover"
          placement="bottomLeft"
          @visibleChange="visibleChange"
        >
          <template slot="content">
            <a-checkbox-group class="media_check_group" :value="groupByList" @change="handleChangeChecked">
              <a-checkbox class="check_box" value="date"> 日期 </a-checkbox>
              <a-checkbox class="check_box" value="plat_place_id"> 平台广告位ID </a-checkbox>
              <a-checkbox class="check_box" value="ad_source_id"> 广告源 </a-checkbox>
            </a-checkbox-group>
          </template>
          <a-button type="link"><a-icon type="radar-chart" />维度</a-button>
        </a-popover>
        <div class="dimension_item" v-for="(item, index) in searchquery.groupByList" :key="index">
          {{ dimensionName[item] }}<a-icon @click="handleCloseItem(item, index)" class="close_icon" type="close" />
        </div>
      </div>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust diagnose_table"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :data-source="dataList"
        tableLayout="fixed"
        :loading="isLoading"
        @change="handleSort"
        :scroll="{ x: true }"
        bordered
      >
        <!-- 日期 -->
        <a-table-column
          :sorter="true"
          :sortDirections="['descend', 'ascend']"
          :sortOrder="sortInfo.date"
          v-if="isShowItem('date')"
          align="left"
          dataIndex="date"
          :width="160"
        >
          <span slot="title" class="hd_tips">日期</span>
        </a-table-column>
        <!-- 广告平台 -->
        <a-table-column align="left" dataIndex="platName" :width="120">
          <span slot="title" class="hd_tips">广告平台</span>
        </a-table-column>
        <!-- 平台广告位ID -->
        <a-table-column v-if="isShowItem('plat_place_id')" align="left" dataIndex="platPlaceId" :width="120">
          <span slot="title" class="hd_tips">平台广告位ID</span>
          <template slot-scope="platPlaceId, record, index">
            <div class="fixed_item" v-if="index !== 0">
              <a-popover placement="bottom" trigger="hover">
                <template #content>
                  <div class="diagnose-popover">
                    <div class="diagnose-popover-item">
                      <span>应用名称：{{ record.appName }}</span>
                      <a-button size="small" type="link" @click="copyId(record.appName)">复制</a-button>
                    </div>
                    <div class="diagnose-popover-item">
                      <span>广告位名称：{{ record.placeName }}</span>
                      <a-button size="small" type="link" @click="copyId(record.placeName)">复制</a-button>
                    </div>
                  </div>
                </template>
                {{ platPlaceId }}
              </a-popover>
            </div>
          </template>
        </a-table-column>
        <!-- 广告源 -->
        <a-table-column v-if="isShowItem('ad_source_id')" align="left" dataIndex="adsourceName" :width="120">
          <span slot="title" class="hd_tips">广告源</span>
        </a-table-column>
        <!-- 错误码 -->
        <a-table-column align="left" dataIndex="code" :width="80">
          <span slot="title" class="hd_tips">错误码</span>
        </a-table-column>
        <!-- 错误说明 -->
        <a-table-column align="left" dataIndex="msg" :width="100">
          <span slot="title" class="hd_tips">错误说明</span>
          <template slot-scope="msg">
            <span v-if="msg" style="font-size: 12px">{{ msg }}</span>
            <span v-else>--</span>
          </template>
        </a-table-column>
        <!-- 请求数 -->
        <a-table-column align="left" dataIndex="requestNum" :width="150">
          <span slot="title" class="hd_tips">请求数</span>
        </a-table-column>
        <!-- 请求失败次数 -->
        <a-table-column
          align="left"
          dataIndex="requestFailNum"
          :sorter="true"
          :sortDirections="['descend', 'ascend']"
          :sortOrder="sortInfo.requestFailNum"
          :width="150"
        >
          <span slot="title" class="hd_tips">请求失败次数</span>
        </a-table-column>
        <!-- 强求失败占比 -->
        <a-table-column
          align="left"
          dataIndex="requestFailProportion"
          :sorter="true"
          :sortDirections="['descend', 'ascend']"
          :sortOrder="sortInfo.requestFailProportion"
          :width="160"
        >
          <span slot="title" class="hd_tips">请求失败占比</span>
          <template slot-scope="requestFailProportion">
            <ProgressBar :per="+requestFailProportion"></ProgressBar>
          </template>
        </a-table-column>
        <!-- 优化建议 -->
        <a-table-column align="left" dataIndex="optimizeSuggest" :width="200">
          <span slot="title" class="hd_tips">优化建议</span>
          <template slot-scope="optimizeSuggest">
            <Suggested :suggest="optimizeSuggest" />
          </template>
        </a-table-column>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="searchquery.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="searchquery.limit"
      />
    </div>
    <m-empty v-if="dataList.length === 0" style="height: 500px">
      <template #description>暂无数据 </template>
    </m-empty>
  </div>
</template>

<script>
import { getThirdPartyErrorAnalysePage } from '@/api/system'
import { mapState } from 'vuex'
import ProgressBar from '../components/ProgressBar.vue'
import Suggested from '../components/Suggested.vue'
import { copy } from '@/utils/string'
import { numFormat } from '@/utils/dealNumber'
export default {
  components: { ProgressBar, Suggested },
  data () {
    return {
      dimensionName: {
        date: '日期',
        plat_place_id: '平台广告位ID',
        ad_source_id: '广告源'
      },
      groupByList: [],
      searchquery: {
        groupByList: [],
        page: 1,
        limit: 10,
        sortBy: '',
        sortOrder: ''
      },
      dataList: [],
      total: 0,
      isLoading: false,
      downLoading: false,
      sortInfo: {
        requestFailNum: false,
        date: false,
        requestFailProportion: false
      }
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    handleSort (pagination, filters, sorter) {
      this.searchquery.sortBy = sorter.field
      this.searchquery.sortOrder = sorter.order ? sorter.order : ''
      for (const key in this.sortInfo) {
        this.sortInfo[key] = false
      }
      this.sortInfo[sorter.field] = sorter.order
      this.searchquery.page = 1
      this.getTableData()
    },
    // 维度选择
    handleChangeChecked (e) {
      this.groupByList = e
    },
    // 关闭popover后进行维度搜索
    visibleChange (e) {
      if (e === false) {
        this.searchquery.groupByList = [...this.groupByList]
        this.getTableData()
      }
    },
    // 关闭单个维度
    handleCloseItem (item, index) {
      this.groupByList.splice(index, 1)
      this.searchquery.groupByList = [...this.groupByList]
      this.getTableData()
    },
    // 供父级调用
    dealquery (query) {
      this.searchquery.page = 1
      this.searchquery = { ...this.searchquery, ...query }
      this.getTableData()
    },
    // 获取table数据
    async getTableData () {
      this.isLoading = true
      const resp = await getThirdPartyErrorAnalysePage(this.searchquery)
      this.total = resp.data ? resp.data.total : 0
      this.dataList = resp.data ? resp.data.items : []
      resp.data.items.map((item) => {
        item.requestNum = numFormat(item.requestNum, 3, '')
        item.requestFailNum = numFormat(item.requestFailNum, 3, '')
      })
      this.isLoading = false
    },
    // 维度tableItem显示控制
    isShowItem (val) {
      return this.searchquery.groupByList.includes(val)
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    }
  }
}
</script>

<style lang="less">
.diagnose-popover {
  width: 240px;
  .diagnose-popover-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }
}
.diagnose-table-container {
  background: #fff;
  min-height: 84vh;
  border-radius: 5px;
  .control {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .control_left {
      display: flex;
      .ant-btn.ant-btn-link {
        padding: 0;
      }
      .dimension_item {
        user-select: none;
        line-height: 28px;
        height: 35px;
        margin: 0 10px;
        background: #eee;
        padding: 3px 10px;
        border-radius: 5px;
        .close_icon {
          color: #000;
          margin: 0 5px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .table {
    .pagination {
      padding-top: 20px;
    }
  }
}
.media_popover {
  .ant-popover-inner-content {
    padding: 0;
  }
  .media_check_group {
    margin: 0;
    display: flex;
    flex-direction: column;

    .check_box.ant-checkbox-wrapper {
      height: 45px;
      line-height: 45px;
      margin: 0px;
      padding: 0 0 0 5px;
      &.ant-checkbox-wrapper-checked {
        color: #40a9ff;
      }
    }
  }
  .table {
    .diagnose_table {
      overflow: hidden;
    }
  }
}
</style>
